<template>
    <div style="height:100%;overflow-y:scroll;">
        <mt-loadmore :bottom-method="loadMore" :bottom-all-loaded="allLoaded" ref="loadmore" :auto-fill="false">
            <ul>
                <li v-for="item in books" v-bind:key="item.id">
                    <div class="book-cover">
                        <router-link :to="{path: '/detail/'+item.id+'.html'}">
                            <img :src="'http://img.books.leizhenxd.com/books/source/'+item.sourceBookId+'/cover.jpg'" :onerror="defaultImg" width="100%">
                        </router-link>
                    </div> 
                    <div class="book-detail">
                        <div class="book-name">{{item.bookName}}</div>
                        <div class="book-desc grey">{{item.description}}</div>
                        <div class="book-desc grey"><span class="orange">{{item.status}}</span> {{item.lastUpdateChapterTime}}</div>
                        <div class="book-desc"><span class="grey">最新: </span>{{item.lastUpdateChapterName}}</div>
                        <div class="book-author">
                            <span>{{item.author}}</span>
                            <div>
                                <span class="book-cat lightgreen">{{item.cat}}</span>
                                <span class="book-wordsnumber lightblue">{{formatterNumber(item.wordsNumber)}}万字</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </mt-loadmore>
    </div>
</template>
<script>
import {query} from '@/apis/api'
import '@/assets/css/style.less'
export default {
    data(){
        return {
            books: [],
            allLoaded: false,
            isLoading: false,
            defaultImg: 'this.src="'+require('@/assets/nocover.jpg')+'"',
            formData:{
                isFinish: true,
                sortType: 2,
                pageNo: 0,
                pageSize: 10
            }
        }
    },
    computed:{
        formatterNumber(){
            return function(wordsNumber){
                return (wordsNumber/10000).toFixed(2);
            }
        }
    },
    created(){
        this.loadMore()
    },
    methods:{
        loadMore(){
            this.isLoading = true;
            this.formData.pageNo += 1;
            query(this.formData).then((data) => {
                this.books = this.books.concat(data.data.data);
                this.isLoading = false
                if(data.data.data && data.data.data.length<this.formData.pageSize){
                    this.allLoaded = true;
                }
                this.$refs.loadmore.onBottomLoaded(); 
            })
            .catch(() => {
                this.isLoading = false
                this.formData.pageNo -= 1;
            })
        }
    }
}
</script>